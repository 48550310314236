
export default class Utility {
    constructor() {}

    // init() {}

    // initAfterFrameworks() {}

    getCookie(a) {
        let result = [], c = document.cookie.split(";");
        a = new RegExp("^\\s*" + a + "=\\s*(.*?)\\s*$");
        for (let d = 0; d < c.length; d++) {
            let e = c[d].match(a);
            e && result.push(e[1]);
        }
        return result[0];
    };

    setCookie(cname, cvalue, exdays) {
        let d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        let expires = 'expires=' + d.toUTCString();
        document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
    };

    createElement(element, classes, id) {

        const elem = document.createElement(element);

        if (classes) {
            classes.trim().split(' ').forEach((classname) => {
                elem.classList.add(classname);
            });

        }

        if (id)
            elem.setAttribute('id', id);

        return elem;

    }

    createLinkElement(classes, tabindex, textContent, ariaLabel, href, id, target) {

        const elem = Utility.createElement('a', classes, id);

        if (tabindex)
            elem.setAttribute('tabindex', tabindex);

        if (textContent)
            elem.textContent = textContent;

        if (ariaLabel)
            elem.ariaLabel = ariaLabel;

        if (href)
            elem.setAttribute('href', href);

        if (target)
            elem.setAttribute('target', target);

        return elem;
    }

    // html decodes a string
    decodeHtml(htmlString) {

        var txt = document.createElement("textarea");
        txt.innerHTML = htmlString;
        return txt.value;

    }

    elementIsVisible(element) {

        if (!element)
            return false;

        // check if the element has dimensions
        return element.offsetWidth > 0 || element.offsetHeight > 0 || element.getClientRects().length > 0;

    }

    // return an updated version of a provided url, where
    // a url parameter is changed (added / value changed / removed)
    updateUrlParameter(uri, key, value) {

        // remove the hash part before operating on the uri
        var i = uri.indexOf('#');
        var hash = i === -1 ? '' : uri.substr(i);
        uri = i === -1 ? uri : uri.substr(0, i);

        var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
        var separator = uri.indexOf('?') !== -1 ? "&" : "?";

        // if no value has been provided
        // remove the param by key from url
        // if it exists
        if (!value) {
            // remove key-value pair if value is empty
            uri = uri.replace(new RegExp("([?&]?)" + key + "=[^&]*", "i"), '');
            if (uri.slice(-1) === '?') {
                uri = uri.slice(0, -1);
            }
            // replace first occurrence of & by ? if no ? is present
            if (uri.indexOf('?') === -1) {
                uri = uri.replace(/&/, '?')
            }
        }
        // if url CONTAINS param by key, && value exists
        // update the value
        else if (uri.match(re)) {
            uri = uri.replace(re, '$1' + key + "=" + encodeURIComponent(value) + '$2');
        }
        // if url DOES NOT CONTAIN param by key, && value exists
        // add the key & value as a new param to the url
        else {
            uri = uri + separator + key + "=" + encodeURIComponent(value);
        }

        return uri + hash;
    }

    showLoader() {
        const loader = document.querySelector('.tps-loader');
        if (loader) {
            loader.classList.remove('hide');
        }
    }

    hideLoader() {
        const loader = document.querySelector('.tps-loader');
        if (loader) {
            loader.classList.add('hide');
        }
    }
    

}
