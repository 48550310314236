export default class CookiesModal {

    constructor() {
        this.disclaimerModal = document.getElementById('cookies-disclaimer-modal');
        this.disclaimerModalReveal = null;
        this.disclaimerModalAcceptButton = document.getElementById('cookies-disclaimer-modal-accept');
        this.disclaimerModalSettingsButton = document.getElementById('cookies-disclaimer-modal-settings');
        this.settingsModal = document.getElementById('cookies-settings-modal');
        this.settingsModalReveal = null;
        this.settingsModalStatisticsSwitch = document.getElementById('cookies-settings-modal-statistics-switch');
        this.settingsModalStatisticsItemSwitches = document.querySelectorAll('[id^=cookies-settings-modal-statistics-switch-item-');
        this.settingsModalFooter = document.getElementById('cookies-settings-modal-footer');
        this.settingsModalConfirmButton = document.getElementById('cookies-settings-modal-confirm');
        this.settingsModalValue = document.getElementById('cookies-settings-modal-value');
        
    }

    // init() {}

    initAfterFrameworks() {
        const _self = tps.cookiesModal;

        if (!_self.disclaimerModal || !_self.settingsModal) {
            return;
        }

        if (_self.disclaimerModal) {
            _self.disclaimerModalReveal = $(_self.disclaimerModal)
        }

        if (_self.settingsModal) {
            _self.settingsModalReveal = $(_self.settingsModal)
        }


        _self.getIndividualCookieSettings();

        if (_self.disclaimerModal.dataset.enabled === "true") {
            _self.disclaimerModalReveal.foundation('open');
        }

        if (_self.disclaimerModalAcceptButton) {
            _self.disclaimerModalAcceptButton.addEventListener('click', function (e) {
                const fetchUrl = new URL('/api/cookielevel/set', location.origin);
                const fetchParams = {
                    cookieLevel: 1000
                };
                fetchUrl.search = new URLSearchParams(fetchParams).toString();
                fetch(fetchUrl, { method: 'POST' }).then(response => {
                    _self.disclaimerModal.dataset.enabled = false;
                    _self.disclaimerModalReveal.foundation('close');
                    _self.setIndividualCookieSettings();
                });
            });
        }

        if (_self.disclaimerModalSettingsButton) {
            _self.disclaimerModalSettingsButton.addEventListener('click', function (e) {
                _self.disclaimerModalReveal.foundation('close');
                _self.settingsModalReveal.foundation('open');

                _self.settingsModalReveal.on('closed.zf.reveal', (e) => {
                    _self.disclaimerModalReveal.foundation('open');
                });
            });
        }

        // "simple" triggers - no need to reopen the disclaimer modal
        const settingsModalTriggers = document.querySelectorAll('[data-settings-modal-trigger]');
        settingsModalTriggers.forEach(trigger => {
            trigger.addEventListener('click', function () {
                _self.settingsModalReveal.foundation('open');
            });
        });

        if (_self.settingsModalStatisticsSwitch) {
            _self.settingsModalStatisticsSwitch.addEventListener('change', function (e) {
                _self.settingsModalFooter.classList.remove('hide');
                if (_self.settingsModalStatisticsSwitch.checked === true) {
                    _self.settingsModalValue.value = 1000;
                    _self.setStatisticsItemSwitchesState(true);
                } else {
                    _self.settingsModalValue.value = 0;
                    _self.setStatisticsItemSwitchesState(false);
                }
            });
        }

        _self.settingsModalStatisticsItemSwitches.forEach(switchItem => switchItem.addEventListener('change', function (e) {
            _self.settingsModalFooter.classList.remove('hide');

            if (_self.allStatisticsItemCookiesDisabled() === true) {
                // Uncheck main statistics switch
                _self.settingsModalStatisticsSwitch.checked = false;
                _self.settingsModalValue.value = 0;
            } else {
                // Check main statistics switch
                _self.settingsModalStatisticsSwitch.checked = true;
                _self.settingsModalValue.value = 1000;
            }
        }));

        if (_self.settingsModalConfirmButton) {
            _self.settingsModalConfirmButton.addEventListener('click', function (e) {
                
                const fetchUrl = new URL('/api/cookielevel/set', location.origin);
                const fetchParams = {
                    cookieLevel: parseInt(_self.settingsModalValue.value)
                };
                fetchUrl.search = new URLSearchParams(fetchParams).toString();
                fetch(fetchUrl, { method: 'POST' }).then(response => {
                    _self.settingsModalReveal.foundation('close')
                    _self.disclaimerModalReveal.foundation('close')
                    _self.setIndividualCookieSettings();
                    _self.settingsModalFooter.classList.add('hide');
                });
            });
        }

        if (_self.settingsModal) {
            _self.handleKeypressEvents();
        }
    }


    setStatisticsItemSwitchesState(enabled) {
        const _self = tps.cookiesModal;
        _self.settingsModalStatisticsItemSwitches.forEach(switchItem =>{
            switchItem.checked = enabled;
        });
    }

    getIndividualCookieSettings() {
        const _self = tps.cookiesModal;
        let cookie = tps.utility.getCookie("MeridianOneCapIndividualCookieSettings");
        if (!cookie) {
            // Set default
            _self.setIndividualCookieSettings();
            return;
        }

        cookie = JSON.parse(cookie);

        _self.settingsModalStatisticsItemSwitches.forEach(switchItem => {
            if (cookie.hasOwnProperty(switchItem.dataset.cookieKey)) {
                switchItem.checked = cookie[switchItem.dataset.cookieKey];
            }
        });

        if (_self.allStatisticsItemCookiesDisabled() === true) {
            // Uncheck main statistics switch
            _self.settingsModalStatisticsSwitch.checked = false;
            _self.settingsModalValue.value = 0;
        }
    }

    setIndividualCookieSettings() {
        const cookieObj = {};
        const _self = tps.cookiesModal;

        _self.settingsModalStatisticsItemSwitches.forEach(switchItem => {
            cookieObj[switchItem.dataset.cookieKey] = switchItem.checked;
        });

        tps.utility.setCookie("MeridianOneCapIndividualCookieSettings", JSON.stringify(cookieObj), 365);
    }

    allStatisticsItemCookiesDisabled() {
        const _self = tps.cookiesModal;

        for (let i = 0; i < _self.settingsModalStatisticsItemSwitches.length; i++) {
            if (_self.settingsModalStatisticsItemSwitches[i].checked === true) {
                return false;
            }
        }

        return true;
    }

    handleKeypressEvents() {
        const _self = tps.cookiesModal;
        const settingsModalCheckbox = _self.settingsModal.querySelectorAll('input[type="checkbox"]');

        if (settingsModalCheckbox && _self.settingsModalStatisticsSwitch) {

            settingsModalCheckbox.forEach((checkbox) => {

                // change the checked state of a checkbox button when the Enter key is pressed
                checkbox.addEventListener('keydown', function (event) {
                    const targetCheckbox = event.target;

                    if (event.key === 'Enter') {
                        // Toggle the checked state
                        if (targetCheckbox.checked) {
                            if (targetCheckbox == _self.settingsModalStatisticsSwitch) {
                                _self.settingsModalValue.value = 0;
                                _self.setStatisticsItemSwitchesState(false);
                                targetCheckbox.checked = false;
                            } else {
                                targetCheckbox.checked = false;
                                _self.settingsModalStatisticsSwitch.checked = false;
                            }
                        } else {
                            if (targetCheckbox == _self.settingsModalStatisticsSwitch) {
                                _self.settingsModalValue.value = 1000;
                                _self.setStatisticsItemSwitchesState(true);
                                targetCheckbox.checked = true;
                            } else {
                                targetCheckbox.checked = true;

                                // Check if all remaining checkboxes are checked
                                const checkboxItems = Array.from(settingsModalCheckbox).slice(1);
                                const allChecked = checkboxItems.every(checkbox => checkbox.checked);
                                if (allChecked) {
                                    _self.settingsModalStatisticsSwitch.checked = true;
                                }    
                            }
                        }
                    }
                });
            });

        }
        
    }
    
}










