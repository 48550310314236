export default class LocationsMap {
    constructor() {
        this.buttonUseCurrent = null;
        this.searchInput = null;
        this.searchSubmit = null;
        this.mapWrapper = null;
        this.searchMessage = null;

        this.startLocation = { lat: 43.6532, lng: -79.3780581 };
        this.svgMarkerPath = 'M15.4393 21.6614C11.8891 21.6248 9.04761 18.7099 9.07034 15.1276C9.09307 11.5074 11.9693 8.6115 15.5248 8.62913C19.071 8.64676 21.9593 11.6091 21.9245 15.1914C21.8897 18.8049 18.9894 21.6981 15.4393 21.6614ZM30.1427 11.7516C28.5769 5.43616 23.723 0.964108 17.3568 0.139417C12.7943 -0.453331 8.71064 0.843389 5.34233 4.04178C1.2827 7.89939 -0.292484 12.6698 0.769224 18.2447C1.35891 21.3427 2.66934 24.183 4.09743 26.9595C6.45752 31.5455 9.30033 35.8195 12.3758 39.9376C13.384 41.2872 14.419 42.6151 15.4767 44C15.5997 43.8562 15.6612 43.7911 15.7161 43.7219C19.3946 38.9949 22.8258 34.0956 25.7595 28.8531C27.4015 25.9165 28.8671 22.8958 29.8485 19.654C30.6388 17.0442 30.8019 14.4088 30.1427 11.7516Z';
        this.googleMap = null;
        this.googleGeocoder = null;
        this.userMarker = null;

        this.branchesData = {};
    }

    init() {
        this.buttonUseCurrent = document.getElementById('find-location-search-usecurrent');
        this.searchInput = document.getElementById('find-location-search-input');
        this.searchSubmit = document.getElementById('find-location-search-submit');
        this.resultsWrapper = document.getElementById('find-location-results');
        this.mapWrapper = document.getElementById('find-location-map');
        this.searchMessage = document.getElementById('find-location-search-error');

        if (this.mapWrapper) {
            this.loadGoogleMapsScript();
            
        }
    }

    loadGoogleMapsScript() {
        let script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${window.tpsSettings.googleMapsKey}&libraries=geometry&callback=tps.locationsMap.initMap`;
        script.async = true;

        // Append the 'script' element to 'head'
        document.head.appendChild(script);
    }

    initMap() {
        const self = this;

        self.googleMap = new google.maps.Map(self.mapWrapper, {
            center: new google.maps.LatLng(self.startLocation),
            zoom: 8,
            disableDefaultUI: true,
        });

        self.googleGeocoder = new google.maps.Geocoder();

        const fetchUrl = new URL('/api/locations/get', location.origin);
        fetch(fetchUrl, { method: 'GET' })
            .then(response => response.json())
            .then(data => {
                let markers = [];

                if (data && data.length > 0) {
                    data.forEach(location => {
                        const customIcon = {
                            path: self.svgMarkerPath,
                            fillColor: '#00567D',
                            fillOpacity: 1.0,
                            strokeWeight: 0,
                            rotation: 0,
                            scale: 1,
                            anchor: new google.maps.Point(30, 44)
                        };

                        const marker = new google.maps.Marker({
                            position: new google.maps.LatLng({ lat: Number.parseFloat(location.lat), lng: Number.parseFloat(location.lng) }),
                            icon: customIcon,
                            map: self.googleMap
                        });

                        markers.push(marker);

                        const infoWindow = new google.maps.InfoWindow({
                            content: `<div class="tps-find-location-map-infowindow">${location.title}</div>`
                        });

                        const listElement = document.querySelector('[data-location-id="' + location.id + '"]');
                        self.branchesData[location.id] = {
                            icon: customIcon,
                            googleMarkerObject: marker,
                            locationData: location,
                            listElement: listElement,
                            infoWindow: infoWindow
                        };

                        marker.addListener('click', function (e) {
                            self.selectLocationElement(location.id);
                        });

                        listElement.addEventListener('click', function (e) {
                            self.selectLocationElement(location.id);
                        });
                    });
                }
            })
            .catch(error => console.log(error));

        self.buttonUseCurrent.addEventListener('click', function (e) {
            self.getCurrentLocation();
        });

        self.searchSubmit.addEventListener('click', function (e) {
            self.searchByInput();
        });
    }

    selectLocationElement(locationID) {
        const self = this;

        for (let key in self.branchesData) {
            const location = self.branchesData[key];

            if (key.toString() === locationID.toString()) {
                location.infoWindow.open({
                    anchor: location.googleMarkerObject,
                    map: self.googleMap,
                    shouldFocus: true,
                });
            } else {
                location.infoWindow.close();
            }
        }

        if (self.googleMap.getZoom() || self.googleMap.getBounds().contains(self.branchesData[locationID].googleMarkerObject.getPosition()) === false) {
            self.zoomIntoLocation(1000, { lat: self.branchesData[locationID].locationData.lat, lng: self.branchesData[locationID].locationData.lng });
        }
    }

    setUserLocation({ lat, lng }) {
        const self = this;

        const position = new google.maps.LatLng({ lat: parseFloat(lat), lng: parseFloat(lng) });

        let distances = [];
        let resultRadius = 5000; // default 5 km if there are markers
        let anyMarkersWithinRadius = false;

        for (const branchID in self.branchesData) {
            const branch = self.branchesData[branchID];

            const distance = google.maps.geometry.spherical.computeDistanceBetween(position, branch.googleMarkerObject.position); // in meters
            distances.push({ branchID: branchID, distance: distance });

            if (distance < 5000) {
                anyMarkersWithinRadius = true;
            }
        }

        distances.sort((a, b) => a.distance - b.distance);

        if (!anyMarkersWithinRadius) {
            resultRadius = distances[0].distance;
        }

        // Sort branch list items
        distances.forEach((distance, index) => {
            self.branchesData[distance.branchID].listElement.style.order = index + 1;
        });

        self.userMarker = new google.maps.Marker({
            position: position,
            map: self.googleMap
        });

        self.zoomIntoLocation(resultRadius, { lat, lng });
    }

    getCurrentLocation() {
        const self = this;

        self.searchMessage.classList.add('hide');
        if (self.userMarker) self.userMarker.setMap(null);

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                const lat = position.coords.latitude;
                const lng = position.coords.longitude;
                self.setUserLocation({ lat, lng });
            });
        } else {
            alert('Geolocation is not available. Please enable geolocation for this website.');
        }
    }

    searchByInput() {
        const self = this;

        self.searchMessage.classList.add('hide');

        if (self.searchInput.value !== '') {
            const query = self.searchInput.value.split(' ').join('+');
            if (self.userMarker) self.userMarker.setMap(null);

                self.googleGeocoder.geocode({ address: query }, (results, status) => {
                    if (status === 'OK') {
                        const lat = results[0].geometry.location.lat();
                        const lng = results[0].geometry.location.lng();

                        self.setUserLocation({ lat, lng });
                    } else {
                        console.log(`Geocode was not successful for the following reason: ${status}`);
                        self.searchMessage.classList.remove('hide');
                    }
                });
        }
    }

    zoomIntoLocation(radius, { lat, lng }) {
        const self = this;

        const position = new google.maps.LatLng({ lat: parseFloat(lat), lng: parseFloat(lng) });
        const circle = new google.maps.Circle({
            center: position,
            fillOpacity: 0,
            strokeOpacity: 0,
            map: self.googleMap,
            radius: radius // radius is in meters
        });

        self.googleMap.fitBounds(circle.getBounds(), 0);
        circle.setMap(null);
    }

    initAfterFrameworks() {
    }
}
