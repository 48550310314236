import ResourcesListing from './ResourcesListing';
import Utility from './tps.utility';
import Navigation from './tps.navigation';
import Header from './tps.header';
import CookiesModal from './tps.cookies-modal';
import Testimonials from './tps.testimonials';
import LocationsMap from './tps.locations';
import Search from './tps.search';
import Tracking from './tps.tracking';

export default class TPS {

    constructor() {
        this.resourcesListing = new ResourcesListing();
        this.utility = new Utility();
        this.navigation = new Navigation();
        this.header = new Header();
        this.cookiesModal = new CookiesModal();
        this.testimonials = new Testimonials();
        this.locationsMap = new LocationsMap();
        this.search = new Search();
        this.tracking = new Tracking();
    }

    // On DOM ready
    onDomReady() {
        const self = this;

        /* Initialize scripts when sections are mounted. Will fire several times, though.
         * TODO: find something elegant without having to run separate inits in layout files. 
         * Kentico's approach on https://docs.xperience.io/k12sp/developing-websites/page-builder-development/developing-widgets-in-mvc didn't work for me.
         */
        document.addEventListener('kenticoSectionMounted', function () {
            self.onDomReadyCallback();
        });

        // On DOM ready
        document.addEventListener('DOMContentLoaded', () => {
            if (document.readyState === 'interactive' || document.readyState === 'complete') {
                self.onDomReadyCallback();
            }
        });
    }

    // On DOM ready (callback)
    onDomReadyCallback() {

        // Init before frameworks
        this.init();

        // Foundation 6 initialization
        $(document).foundation();

        // Init after frameworks
        this.initAfterFrameworks();
    }

    // Init before frameworks
    init() {
        for (var i in this) {
            if (typeof this[i].init === 'function') {
                this[i].init();
            }
        }
    }

    // Init after frameworks
    initAfterFrameworks() {
        for (var i in this) {
            if (typeof this[i].initAfterFrameworks === 'function') {
                this[i].initAfterFrameworks();
            }
        }
    }

}
