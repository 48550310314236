
export default class Header {
    constructor() {
        this.header = document.querySelector('header');
        this.isTouchDevice = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);
        this.lastKnownScrollPosition = 0;
        this.ticking = false;

    }

    // init() {}

    initAfterFrameworks() {

        const _self = tps.header;

        // we only care about the sticky header on
        // larger screens
        if (_self.header) {
            _self.setupScrollEvents();
        }
    }

    setupScrollEvents() {

        const _self = tps.header;

        _self.lastKnownScrollPosition = window.scrollY;
        _self.checkScrollPosition();

        document.addEventListener('scroll', evt => {
            _self.lastKnownScrollPosition = window.scrollY;
            
            if (!_self.ticking) {
                window.requestAnimationFrame(function () {
                    _self.checkScrollPosition();
                    _self.ticking = false;
                });

                _self.ticking = true;
            }
        });


    }

    checkScrollPosition() {

        const _self = tps.header;
        const activeMenuItems = document.querySelectorAll('.tps-site-header li.expanded');
        // reset all the active menu items
        // when the menu is scrolling
        activeMenuItems.forEach(item => {
            tps.navigation.clearItemActiveState(item);
        });

        if (_self.lastKnownScrollPosition > 0) {
            document.body.classList.add('tps-header-sticky')
        } else {
            document.body.classList.remove('tps-header-sticky')
        }
    }

}
