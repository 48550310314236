export default class Tracking {
    constructor() {
        this.accordions = null;
        this.forms = null;
    }

    init() {

        // unambigious reference to the current class
        const _self = tps.tracking;

        // first we will setup the dataylayer object, just in case
        // it has not yet been created by GTM
        window.dataLayer = window.dataLayer || [];

        // get all the accordions on the page
        // the accordions are created using the Foundation FE framework
        // https://get.foundation/sites/docs/accordion.html
        // so we can find all the accordions using this attribute
        _self.accordions = $('[data-accordion]');
        if (_self.accordions.length > 0) {
            _self.trackAccordions();
        }

        // tracking form submits will be done in a different way
        // depending on how the form is configured in kentico
        // if the form is setup to return a message, or reload
        // then we will add an event listenter to the form
        // get all the forms on the page
        _self.forms = $('form[data-ktc-ajax-update]');
        if (_self.forms.length > 0) {
            _self.trackFormSubmits();
        }

        // if the form is configured to redirect to a thank-you page
        // we will need to use a different way to track, b/c we will
        // lose the context of the form submit.
        // check for the URL parameter on the success page
        if (location.search.indexOf('formName') > -1) {
            // do something with the URL parameter name

            /*
            window.dataLayer.push({
                'event': 'formComplete',
                'formName': `Get the value of the formName URL parameter`
            });
            */
        }
    }


    trackAccordions() {
        // unambigious reference to the current class
        const _self = tps.tracking;

        // the foundation accordion represents
        // the accoridon group, not the individual controls
        // but we can use foundation to get the open/close events
        // for the accordion
        // https://get.foundation/sites/docs/accordion.html#js-events
        _self.accordions.each((key, elem) => {

            const accordion = $(elem);

            // add a listener on the open
            accordion.on('down.zf.accordion', (event, targetContent) => {
                // get the title text of the accordion title element
                const titleText = $(`#${targetContent[0].getAttribute('aria-labelledby')}`).text();

                window.dataLayer.push({
                    'event': 'accordionClick',
                    'accordionTitle': titleText,
                    'accordionState': 'opened'
                });

            })

            // add a listener on the close
            accordion.on('up.zf.accordion', (event, targetContent) => {
                // get the title text of the accordion title element
                const titleText = $(`#${targetContent[0].getAttribute('aria-labelledby')}`).text();

                window.dataLayer.push({
                    'event': 'accordionClick',
                    'accordionTitle': titleText,
                    'accordionState': 'closed'
                });

            })

        })

    }

    trackFormSubmits() {
        // unambigious reference to the current class
        const _self = tps.tracking;

        // for the form submit, there is no direct way to know when
        // the form is submitted. The form is rendered 100% by kentico api,
        // and the submit is handled by .net MVC (using jquery unobtrusive)
        // https://github.com/aspnet/jquery-validation-unobtrusive

        // we can however listen for the submit action on the form, for example:
        _self.forms.each((key, elem) => {

            const formID = elem.id;
            const form = $(elem);

            // we can check for the submit event on the form
            _self.addFormTracking(form, formID)

        });

    }

    addFormTracking(_form, _formID) {

        // unambigious reference to the current class
        const _self = tps.tracking;

        // we need to check if there are visible
        // errors already on the form
        const _formErrors = _form.find('.field-validation-error');
        const _formHasErrors = _formErrors.length > 0;

        _form.on('submit', (e) => {


            // in cases where there were previous validation errors
            // and we are tracking the second form submit
            // we need to remove the validation error elements
            // so we can check if they exist on the submit response below
            if (_formHasErrors) {
                _formErrors.remove();
            }

            const formInterval = setInterval(() => {


                // we need to check if there are errors that are visible
                // as there could be hidden errors after a validation issue is
                // returned but corrected on the second submit
                const errors = $(`#${_formID} .field-validation-error`);
                const successMessage = $(`.formwidget-submit-text`);




                if (successMessage.length > 0) {
                    // stop checking for the submit
                    clearInterval(formInterval);

                    // we need to do some work to get the form name
                    // from the form element by using the id attribute
                    // which has a format of `id="form-<Form Name concatenated>-<A unique identifier>"`
                    const formName = _formID.split('-')[1];


                    window.dataLayer.push({
                        'event': 'formComplete',
                        'formName': formName
                    });

                } else if (errors.length > 0) {
                    // stop checking for the submit
                    // we need to bind to the form again
                    clearInterval(formInterval);

                    // when the MVC partial view for the form is updated
                    // the element is replaced in the DOM so
                    // we need to bind to the form submit event again
                    _self.addFormTracking($(`#${_formID}`), _formID);

                }

            }, 500);

        });
    }

}
