import Glide from '@glidejs/glide'

export default class Testimonials {
    constructor() {
        this.carousels = document.querySelectorAll('.tps-testimonials-carousel');
    }

    // init() {}

    initAfterFrameworks() {


        const _self = tps.testimonials;

        if (_self.carousels.length > 0) {
            _self.initCarousels();
        }

    }

    initCarousels() {

        const _self = tps.testimonials;

        _self.carousels.forEach(carousel => {

            const items = carousel.querySelectorAll('.tps-testimonials-list-item');

            if (items.length > 1) {
                new Glide(`#${carousel.id}`, {
                    type: 'carousel',
                    autoplay: false,
                    gap: 20,
                    startAt: 0,
                    perView: 2,
                    breakpoints: {
                        767: {
                            perView: 1
                        }
                    }
                }).mount()
            }

        });

    }

}
