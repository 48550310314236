
export default class Search {
    constructor() {}

    // init() {}

    // initAfterFrameworks() {}

    updateHistory() {

        //const _self = tps.search;
        const input = document.getElementById('SearchText');
        const url = tps.utility.updateUrlParameter(window.location.pathname + window.location.search, 'q', input.value);

        // push the new search query string to the browser history
        window.history.replaceState({ 'url': url }, 'page', url);  
    }
}
