export default class ResourcesListing {
    constructor() {
        this.getResourcesWS = "/api/GetResourcesCards";

        this.inputNodeAlias = null;
        this.inputSearch = null;
        this.buttonSearch = null;
        this.selectCategory = null;
        this.selectSort = null;
        this.resultsWrapper = null;
        this.loader = null;
    }

    init() {
        this.inputNodeAlias = document.getElementById('resource-listing-nodealiaspath');
        this.inputSearch = document.getElementById('resource-listing-search');
        this.buttonSearch = document.getElementById('resource-listing-search-submit');
        this.selectCategory = document.getElementById('resource-listing-category');
        this.selectSort = document.getElementById('resource-listing-sort');
        this.resultsWrapper = document.getElementById('resource-listing-results');
        this.loader = document.getElementById('resource-listing-loader');
    }

    initAfterFrameworks() {
        if (this.inputNodeAlias && this.inputSearch && this.buttonSearch && this.selectCategory && this.selectSort && this.resultsWrapper) {
            this.initFilters();
            this.getResults();
        }
    }

    initFilters() {
        const self = this;

        self.buttonSearch.addEventListener('click', function (e) { self.getResults() });
        self.selectCategory.addEventListener('change', function (e) { self.getResults() });
        self.selectSort.addEventListener('change', function (e) { self.getResults() });
    }

    getResults(pageNumber = 1) {
        const self = this;

        self.loader.classList.remove('hide');

        $.ajax({
            type: 'GET',
            url: self.getResourcesWS,
            dataType: 'json',
            data: {
                nodeAliasPath: self.inputNodeAlias.value,
                search: self.inputSearch.value,
                category: self.selectCategory.value,
                sort: self.selectSort.value,
                page: pageNumber
            }
        }).done(function (res) {
            if (res != null) {
                self.resultsWrapper.innerHTML = res.cardsHtml;
                $(self.resultsWrapper).foundation();
                self.initPagination();
                if (self.inputSearch.value != '') {
                    window.dataLayer.push({
                        event: "resourceSearch",
                        query: self.inputSearch.value,
                    });
                }
            }
        }).always(function () {
            self.loader.classList.add('hide');
        });
    }

    initPagination() {
        const self = this;

        const buttons = document.querySelectorAll('.tps-resource-listing-page-pagination [data-page]');

        buttons.forEach(function (button) {
            button.addEventListener('click', function (e) {
                self.getResults(button.dataset.page);
            });
        });
    }
}
