export default class Navigation {
    constructor() {
        this.offcanvasUtilityItems = document.querySelectorAll('.tps-site-offcanvas-utility-menu-list-item.level-1');
        this.mobileItems = document.querySelectorAll('.tps-site-navigation-mobile-list-item.level-1');
        this.utilityItems = document.querySelectorAll('.tps-site-header-utility-menu-list-item.level-1');
        this.menuItems = document.querySelectorAll('.tps-site-navigation-list-item.level-1');
        this.menuItemsDropdownToggles = document.querySelectorAll('.tps-menu-link-toggle');
        this.isTouchDevice = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);
    }

    init() {

        const _self = tps.navigation;

        _self.setupDropdownEvents();

    }

    //initAfterFrameworks() { }

    setupDropdownEvents() {

        const _self = tps.navigation;

        // if its not a touch device
        // add the mouse events (mouseover, mouseout)
        if (!self.isTouchDevice) {

            _self.utilityItems.forEach(item => {
                _self.addMouseEvents(item)
            });

            _self.menuItems.forEach(item => {
                _self.addMouseEvents(item)
            });
        }

        _self.menuItemsDropdownToggles.forEach(toggle => {

            toggle.addEventListener('click', evt => {
                evt.preventDefault();
                const elem = evt.currentTarget;

                if (elem.ariaExpanded === "true") {
                   _self.clearItemActiveState(elem.parentElement, elem);
                } else {
                   _self.clearAllItemActiveStates();
                   _self.applyItemActiveState(elem.parentElement, elem);
                }

            });

        });

    }

    addMouseEvents(item) {
        const _self = tps.navigation;

        item.addEventListener("mouseover", evt => {

            const elem = evt.currentTarget;
            _self.clearAllItemActiveStates();
            _self.applyItemActiveState(elem);
        });

        item.addEventListener("mouseout", evt => {
            const elem = evt.currentTarget;
            _self.clearItemActiveState(elem);
        });
    }

    clearAllItemActiveStates() {
        const _self = tps.navigation;

        _self.menuItems.forEach(item => {
            _self.clearItemActiveState(item);
        });

    }

    applyItemActiveState(item, toggler) {

        if (!toggler) {
            toggler = item.querySelector('.tps-menu-link-toggle');
        }

        item.classList.add('expanded');

        if (toggler) {
            toggler.ariaExpanded = true
        } 

    }

    clearItemActiveState(item, toggler) {

        if (!toggler) {
            toggler = item.querySelector('.tps-menu-link-toggle');
        }

        item.classList.remove('expanded');

        if (toggler) {
            toggler.ariaExpanded = false;
        }

    }


}
